import './index.scss';

import React, { useCallback, useState } from 'react';
import { Link as RRLink } from 'react-router-dom';

import { appendClassProps } from '../util';
import { LinkProps } from './index.types';
/**
 - Use a link to either route to a url, or as a generic clickable text component
 */
export const Link: React.FC<LinkProps> = ({
  newTab = false,
  url,
  href,
  state,
  inline,
  nowrap,
  color,
  onClick,
  className,
  children,
  target,
  rel,
  tooltip,
  disabled,
  'data-testid': dataTestId = 'link',
  'data-pwid': dataPwId = 'link',
}: LinkProps) => {
  const [depressed, setDepressed] = useState(false);

  const onMouseDown = useCallback(() => {
    setDepressed(true);
  }, []);

  const onMouseUp = useCallback(() => {
    setDepressed(false);
  }, []);

  let normalColorClass = 'text-blue-500';
  let depressedColorClass = 'hover:text-blue-600';
  let hoverColorClass = 'hover:text-blue-400';

  if (color === 'green') {
    normalColorClass = 'text-emerald-500';
    depressedColorClass = 'text-emerald-600';
    hoverColorClass = 'text-emerald-400';
  } else if (color === 'white') {
    normalColorClass = 'text-gray-50';
    depressedColorClass = 'hover:text-gray-150';
    hoverColorClass = 'hover:text-gray-200';
  } else if (color === 'dark-blue') {
    normalColorClass = 'text-blue-800';
  }

  const classes = `text-base font-normal ${normalColorClass}${appendClassProps(className)} ${
    depressed ? depressedColorClass : ''
  }${nowrap ? ' text-ellipsis overflow-hidden whitespace-nowrap' : ''}${inline ? '' : ' block'} ${
    disabled ? 'disabled-link' : 'cursor-pointer ' + hoverColorClass
  }`;

  let path = url;
  if (!url) path = href;
  if (disabled) path = undefined;
  const isExternal = path?.startsWith('http') || path?.startsWith('tel:') || path?.startsWith('mailto:');

  return isExternal || onClick ? (
    <a
      data-testid={dataTestId}
      data-pwid={dataPwId}
      data-tip={tooltip}
      className={classes}
      href={path}
      onClick={disabled ? undefined : onClick}
      onMouseDown={onMouseDown}
      onMouseUp={onMouseUp}
      target={newTab ? 'blank' : undefined}
      rel={newTab ? 'noopener noreferrer' : undefined}
    >
      {children}
    </a>
  ) : (
    <RRLink
      data-tip={tooltip}
      data-pwid={dataPwId}
      data-testid={dataTestId}
      className={classes}
      to={path ?? ''}
      onMouseDown={onMouseDown}
      onMouseUp={onMouseUp}
      target={target ?? newTab ? 'blank' : undefined}
      rel={rel ?? newTab ? 'noopener noreferrer' : undefined}
      state={state}
    >
      {children}
    </RRLink>
  );
};

export * from './index.types';
