import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { Input, InputType } from '../../Input';
import { Text, TextType } from '../../Text';
import { Range as IRange } from '../index.types';

interface RangeProps {
  range?: IRange;
  loading?: boolean;
  onChange: (newRange: IRange) => void;
}

const Range: React.FC<RangeProps> = ({ range, loading, onChange }: RangeProps) => {
  const parseRange = useCallback((range: IRange) => {
    if (range?.from && typeof range.from !== 'number') {
      try {
        range.from = parseFloat(range?.from as string);
      } catch {
        range.from = undefined;
      }
    }
    if (range?.to && typeof range.to !== 'number') {
      try {
        range.to = parseFloat(range?.to as string);
      } catch {
        range.to = undefined;
      }
    }
  }, []);

  const defaultRange = useMemo(() => {
    const defaultRange = { ...range };
    parseRange(defaultRange);
    return defaultRange as IRange;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [range]);

  const [from, setFrom] = useState<number | undefined>(defaultRange?.from);
  const [to, setTo] = useState<number | undefined>(defaultRange?.to);
  const [lastChanged, setLastChanged] = useState<'to' | 'from' | undefined>();

  useEffect(() => {
    onChange({
      from,
      to,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [from, to]);

  useEffect(() => {
    if (range) {
      parseRange(range);
      if (range.from) setFrom(range.from);
      if (range.to) setTo(range.to);
    } else {
      setFrom(undefined);
      setTo(undefined);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [range]);

  const negativeRangeWarning = useMemo(
    () => (
      <Text className="text-red-500" type={TextType.custom}>
        Range must be positive
      </Text>
    ),
    [],
  );

  return (
    <div className="flex flex-row gap-2 p-2 text-base font-normal">
      <div className="flex flex-col gap-2">
        <Input
          className="flex-1"
          hideErrorSection
          type={InputType.number}
          onChangeValue={(value: string | number) => {
            value ? setFrom(value as number) : setFrom(undefined);
            setLastChanged('from');
          }}
          placeholder={from?.toString()}
          value={from}
          loading={loading}
          onBlur={() => {
            // prevent negative ranges
            if (from && to && from > to) {
              setFrom(to);
              setLastChanged(undefined);
            }
          }}
        />
        {to && from && to < from && lastChanged === 'from' && negativeRangeWarning}
      </div>
      {!loading && <Text inline>-</Text>}
      <div className="flex flex-col gap-2">
        <Input
          className="flex-1"
          hideErrorSection
          type={InputType.number}
          onChangeValue={(value: string | number) => {
            value ? setTo(value as number) : setTo(undefined);
            setLastChanged('to');
          }}
          placeholder={to?.toString()}
          value={to}
          loading={loading}
          onBlur={() => {
            // prevent negative ranges
            if (from && to && to < from) {
              setTo(from);
              setLastChanged(undefined);
            }
          }}
        />
        {to && from && to < from && lastChanged === 'to' && negativeRangeWarning}
      </div>
    </div>
  );
};

export default Range;
