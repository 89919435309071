import * as HeroIconsOutline from '@heroicons/react/outline';
import React, { SVGProps, useEffect, useState } from 'react';
import Skeleton from 'react-loading-skeleton';
import ReactSelect, {
  components,
  DropdownIndicatorProps,
  OptionProps,
  StylesConfig,
  Theme,
  ValueContainerProps,
} from 'react-select';

import { Error } from '../Error';
import { Label } from '../Label';
import colors from '../style/colors';
import { appendClassProps } from '../util';
import { SelectProps, SelectValue } from './index.types';

type OptionType = { label: string; value: number };

export const Select: React.FC<SelectProps> = ({
  disabled = false,
  searchable = false,
  clearable = true,
  loading = false,
  menuPlacement = 'auto',
  label,
  value,
  options,
  errorMessage,
  revealErrorMessage,
  tooltip,
  className,
  hideErrorSection,
  leftIcon,
  placeholder,
  autoFocus,
  isMulti,
  openOnMount,
  onChangeValue,
  'data-pwid': dataPwId = 'select',
}: SelectProps) => {
  const [dirty, setDirty] = useState(false);
  const [_placeholder, setPlaceholder] = useState(placeholder);
  const [menuIsOpen, setMenuIsOpen] = useState(openOnMount);

  useEffect(() => {
    setPlaceholder(placeholder);
  }, [placeholder]);

  let LeftIcon: ((props: SVGProps<SVGSVGElement>) => JSX.Element) | undefined = undefined;
  if (leftIcon) LeftIcon = HeroIconsOutline[leftIcon];

  const showErrorMessage = errorMessage && (revealErrorMessage || dirty) ? true : false;

  const handleChange = (newValue: unknown) => {
    setDirty(true);
    onChangeValue &&
      onChangeValue(
        (newValue as OptionType)?.hasOwnProperty('value') ? (newValue as OptionType).value : (newValue as SelectValue),
      );
  };

  const handleBlur = () => {
    setDirty(true);
  };

  const ValueContainer = ({ children, ...props }: ValueContainerProps) => (
    <components.ValueContainer {...props}>
      <div className={`relative flex flex-row h-full items-center${LeftIcon ? ' pl-[2rem]' : ''}`}>
        {/* {LeftIcon && <LeftIcon className="h-6 left-2 w-6 text-gray-400" data-testid="left-icon" />} */}
        {LeftIcon && (
          <span
            className={`absolute inset-y-0 left-1 flex items-center text-gray-400  ${
              disabled ? 'cursor-not-allowed' : 'cursor-text'
            } `}
          >
            {typeof leftIcon === 'string' ? <LeftIcon data-testid="left-icon" className="h-6 w-6" /> : leftIcon}
          </span>
        )}
        {children}
      </div>
    </components.ValueContainer>
  );

  const Option = ({ children, ...props }: OptionProps) => (
    <components.Option {...props}>
      <div data-pwid={`select-option-${props.label}`}>{children}</div>
    </components.Option>
  );

  const DropdownIndicator = (props: DropdownIndicatorProps) => (
    <components.DropdownIndicator {...props}>
      <HeroIconsOutline.ChevronDownIcon className="h-5 w-5 lg:h-6 lg:w-6" />
    </components.DropdownIndicator>
  );

  const styles: StylesConfig = {
    input: (base) => ({
      ...base,
      padding: '0',
      margin: '0',
    }),

    control: (base, { isDisabled }) => ({
      ...base,
      height: '3rem',
      minHeight: '30px',
      boxShadow: 'none',
      backgroundColor: isDisabled ? colors.gray['150'] : colors.white,
      borderColor: isDisabled ? colors.gray['300'] : undefined,
      borderRadius: '0',
    }),

    valueContainer: (base) => ({
      ...base,
      padding: '0 0.25rem',
    }),

    multiValue: (base) => ({
      ...base,
      height: '20px',
    }),

    singleValue: (base, { isDisabled }) => ({
      ...base,
      color: isDisabled ? colors.gray['600'] : colors.blue['800'],
    }),

    dropdownIndicator: (base) => ({
      ...base,
      height: '34px',
      alignItems: 'center',
      padding: '0 0.25rem 0 0.25rem',
    }),

    clearIndicator: (base) => ({
      ...base,
      height: '34px',
      alignItems: 'center',
      padding: '0 0.25rem 0 0.25rem',
    }),

    indicatorSeparator: () => ({
      display: 'none',
    }),

    menu: (base) => ({
      ...base,
      color: colors.blue['800'],
    }),

    placeholder: (base) => ({
      ...base,
      color: colors.gray['400'],
    }),
  };

  const theme = (theme: Theme) => ({
    ...theme,
    colors: {
      ...theme.colors,
      primary: colors.blue['800'],
      primary75: colors.blue['600'],
      primary50: colors.blue['400'],
      primary25: colors.blue['200'],

      danger: colors.red['500'],
      dangerLight: colors.red['300'],

      neutral0: colors.white,
      neutral5: colors.gray['50'],
      neutral10: colors.gray['100'],
      neutral20: colors.gray['200'],
      neutral30: colors.gray['300'],
      neutral40: colors.gray['400'],
      neutral50: colors.gray['500'],
      neutral60: colors.gray['600'],
      neutral70: colors.gray['700'],
      neutral80: colors.gray['800'],
      neutral90: colors.gray['900'],
    },
  });

  return (
    <div
      data-testid="select"
      data-pwid={dataPwId}
      className={`${className?.includes('absolute') ? '' : 'relative'}${
        disabled ? ' cursor-not-allowed' : ''
      }${appendClassProps(className)}`}
    >
      {label && (
        <Label dataTestId="label" tooltip={tooltip}>
          {label}
        </Label>
      )}
      {loading ? (
        <Skeleton width={'100%'} height={'34px'} />
      ) : (
        <ReactSelect
          className="text-base"
          isDisabled={disabled}
          value={
            isMulti
              ? (options.filter(function (option) {
                  return (value as SelectValue[])?.includes(option.value as SelectValue);
                }) as unknown) ?? null
              : (options.find(function (option) {
                  return option.value === value;
                }) as unknown) ?? null
          }
          isSearchable={searchable}
          isClearable={clearable}
          options={options}
          menuPortalTarget={document.body}
          styles={styles}
          theme={theme}
          onChange={handleChange}
          components={{
            ValueContainer,
            DropdownIndicator,
            Option,
          }}
          onBlur={handleBlur}
          placeholder={_placeholder}
          autoFocus={autoFocus}
          menuPlacement={menuPlacement}
          isMulti={isMulti}
          menuIsOpen={menuIsOpen}
          onMenuClose={() => setMenuIsOpen(undefined)}
        />
      )}
      {!hideErrorSection && <Error errorMessage={errorMessage} showErrorMessage={showErrorMessage} />}
    </div>
  );
};

export * from './index.types';
