import colors from '../style/colors';

export const appendClassProps = (className?: string | string[]): string => {
  if (Array.isArray(className)) {
    return className.reduce((final: string, name: string) => {
      if (name) {
        final = final + ' ' + name;
      }
      return final;
    }, '');
  }
  return className ? ' ' + className : '';
};

/**
 * Returns whether the string conforms to the RFC 5322 Official Standard
 * @param candidate
 * @returns {boolean}
 */
export const isEmail = (candidate: string): boolean => {
  // eslint-disable-next-line no-control-regex
  return /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/.test(
    candidate,
  );
};

export const colorChoices = [
  colors.emerald['500'],
  colors.red['600'],
  colors.orange['500'],
  colors.indigo['400'],
  colors.violet['900'],
  colors.violet['400'],
  colors.pink['400'],
  colors.violet['700'],
];

/* DJB Hash Algorithm */
export function hash(input: string): number {
  let h = 5381; // magic number for less collisions
  for (let i = 0; i < input.length; i++) {
    const ascii = input.charCodeAt(i); // grab ASCII integer
    h = (h << 5) + h + ascii; // bitwise operations
  }
  return Math.abs(h & 0xffffffff);
}

export function getUniquePalletteColor(unique = ''): string {
  const digest = hash(unique);

  const numberColors = colorChoices.length;

  const index = digest % numberColors;

  const randomColor = colorChoices[index];

  return randomColor;
}

export function clipString(str: string, length: number): string {
  return str.length > length ? str.substring(0, length - 3) + '...' : str;
}
