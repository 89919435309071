import React, { ReactNode } from 'react';

export enum Method {
  get = 'get',
  post = 'post',
  put = 'put',
  patch = 'patch',
}
export interface FormProps {
  children: ReactNode;
  preventDefault?: boolean;
  className?: string | string[];
  method?: Method;
  onSubmit: (e: React.FormEvent) => void;
}

export type FormStatus = 'success' | 'loading' | 'dirty';
