import 'react-toastify/dist/ReactToastify.css';

import structuredClone from '@ungap/structured-clone';
import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import ReactTooltip from 'react-tooltip';

import colors from './ComponentLibrary/src/style/colors';
import Routes from './Routes';

// Polyfills
if (!('structuredClone' in globalThis)) {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  globalThis.structuredClone = structuredClone;
}

function App(): JSX.Element {
  return (
    <Router>
      {process.env.REACT_APP_ENV !== 'production' && (
        <div id="watermark">
          <p>{process.env.REACT_APP_ENV?.toUpperCase()}</p>
        </div>
      )}
      <Routes />
      <ReactTooltip
        className="max-w-[70vw] overflow-hidden break-normal"
        effect="solid"
        textColor={colors.gray['50']}
        backgroundColor={colors.blue['800']}
        multiline
        // Fix overflow on mobile
        overridePosition={({ left, top }, event, triggerElement, tooltipElement) => {
          return {
            top,
            left: Math.min(left, window.innerWidth - (tooltipElement?.offsetWidth ?? 0)),
          };
        }}
      />
      <ToastContainer position="top-center" hideProgressBar autoClose={3000} limit={5} newestOnTop />
    </Router>
  );
}

export default App;
