import * as HeroIconsOutline from '@heroicons/react/outline';
import React, {
  ChangeEvent,
  ForwardedRef,
  forwardRef,
  KeyboardEvent,
  MutableRefObject,
  SVGProps,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import Skeleton from 'react-loading-skeleton';

import { Error } from '../Error';
import { Label } from '../Label';
import { applyInputStyles } from '../style/util';
import { appendClassProps } from '../util';
import { TextAreaProps } from './index.types';

// Component for conditionally forwarding ref
const UseOverrideRef = ({
  forwardedRef,
  inputRef,
}: {
  forwardedRef: ForwardedRef<HTMLTextAreaElement>;
  inputRef: MutableRefObject<HTMLTextAreaElement>;
}) => {
  useImperativeHandle(forwardedRef, () => {
    return {
      ...inputRef?.current,
      focus: () => {
        inputRef?.current?.focus();
      },
      getBoundingClientRect: () => inputRef?.current.getBoundingClientRect(),
      offsetParent: inputRef?.current?.offsetParent,
    };
  });
  return null;
};
/**
 - This is a controlled component for text input
 */
const TextArea = (
  {
    autoFocus = false,
    revealErrorMessage = false,
    disabled = false,
    hideErrorSection = false,
    name,
    label,
    tooltip,
    placeholder,
    leftIcon,
    rightIcon,
    errorMessage,
    value,
    className,
    inputClassName,
    loading,
    onChangeValue,
    onChange,
    onPressEnter,
    onBlur,
    onClickRightIcon,
    'data-pwid': dataPwid = 'text-area',
    rows = 5,
  }: TextAreaProps,
  ref: ForwardedRef<HTMLTextAreaElement>,
): JSX.Element => {
  const inputRef = useRef<HTMLTextAreaElement>(null);
  const [dirty, setDirty] = useState(false);

  let LeftIcon: ((props: SVGProps<SVGSVGElement>) => JSX.Element) | undefined = undefined;
  let RightIcon: ((props: SVGProps<SVGSVGElement>) => JSX.Element) | undefined = undefined;
  if (leftIcon) LeftIcon = HeroIconsOutline[leftIcon];
  if (rightIcon) {
    RightIcon = HeroIconsOutline[rightIcon];
  }

  const showErrorMessage = errorMessage && (revealErrorMessage || dirty) ? true : false;

  const handleChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
    const target = event.target as HTMLTextAreaElement;
    if (typeof onChange === 'function') {
      onChange(event);
    } else if (typeof onChangeValue === 'function') {
      onChangeValue(target.value);
    }
  };

  const handleBlur = () => {
    setDirty(true);
    if (onBlur) onBlur();
  };
  const handleClickRightIcon = () => {
    if (onClickRightIcon) onClickRightIcon();
  };
  const onClickLeftIcon = () => {
    inputRef?.current?.focus();
  };
  const onKeyUp = (event: KeyboardEvent<HTMLTextAreaElement>) => {
    if (event.key === 'Enter' && typeof onPressEnter === 'function') onPressEnter(event);
  };

  return (
    <div data-testid="div" className={`flex flex-col text-blue-800${appendClassProps(className)}`}>
      {label && (
        <Label dataTestId="label" tooltip={tooltip}>
          {label}
        </Label>
      )}

      {/* Conditionally override ref */}
      {ref && Object.keys(ref).length > 0 ? (
        <UseOverrideRef forwardedRef={ref} inputRef={inputRef as MutableRefObject<HTMLTextAreaElement>} />
      ) : null}

      <div className="relative min-w-36 w-full">
        {LeftIcon && (
          <span
            className={`absolute inset-y-0 left-2 flex items-center text-gray-400  ${
              disabled ? 'cursor-not-allowed' : 'cursor-text'
            } `}
            onClick={onClickLeftIcon}
          >
            <LeftIcon data-testid="left-icon" className="h-5 w-5 lg:h-6 lg:w-6" />
          </span>
        )}
        {RightIcon && (
          <span
            className={`absolute inset-y-0 right-2 flex items-center text-gray-400 hover:text-gray-600`}
            onClick={handleClickRightIcon}
          >
            <RightIcon data-testid="right-icon" className="h-5 w-5 lg:h-6 lg:w-6" />
          </span>
        )}
        {loading ? (
          <>
            <Skeleton width={'100%'} />
            <Skeleton width={'100%'} />
            <Skeleton width={'100%'} />
            <Skeleton width={'75%'} />
            <Skeleton width={'75%'} />
            <Skeleton width={'50%'} />
          </>
        ) : (
          <textarea
            rows={rows}
            ref={inputRef}
            name={name}
            onChange={handleChange}
            onBlur={handleBlur}
            onKeyUp={onKeyUp}
            data-testid="textArea"
            data-pwid={dataPwid}
            placeholder={placeholder}
            autoFocus={autoFocus}
            className={applyInputStyles({
              showErrorMessage,
              inputClassName,
              disabled,
              multiLine: true,
            })}
            value={value}
            disabled={disabled}
          />
        )}
      </div>
      {!hideErrorSection && <Error errorMessage={errorMessage} showErrorMessage={showErrorMessage} />}
    </div>
  );
};

const WrappedTextArea = forwardRef<HTMLTextAreaElement, TextAreaProps>(TextArea);
export * from './index.types';
export { TextArea as BareTextArea, WrappedTextArea as TextArea }; // for storybook (fails to properly parse component if wrapped with forwardRef)
