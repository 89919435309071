import { RefObject, useEffect, useRef } from 'react';
import { useMediaQuery } from 'react-responsive';

import { screenSizeMediaQuery } from './constants';

/**
 * Hook that alerts clicks outside of the passed ref
 */
export function useOutsideAlerter(refs: RefObject<unknown> | RefObject<unknown>[] | null, callback: () => void): void {
  if (!Array.isArray(refs) && refs) refs = [refs];
  useEffect(() => {
    /**
     * Alert if clicked on outside of element
     */
    if (!refs) return;
    const handleClickAnywhere = (event: MouseEvent) => {
      let clickIsInsideARef = false;
      for (const ref of refs as RefObject<unknown>[]) {
        if (!ref.current) continue;
        if (event.composedPath().some((ancestor) => ancestor === ref.current)) {
          clickIsInsideARef = true;
        }
      }
      return clickIsInsideARef ? undefined : callback();
    };

    // Bind the event listener
    document.addEventListener('mousedown', handleClickAnywhere);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickAnywhere);
    };
  }, [refs, callback]);
}

export function useMounted(): boolean {
  const isMounted = useRef(false);

  useEffect(() => {
    isMounted.current = true;
    return () => {
      isMounted.current = false;
    };
  }, []);

  return isMounted.current;
}

export function useMobile(): boolean {
  return useMediaQuery(screenSizeMediaQuery.mobile);
}
