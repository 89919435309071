import { RefObject } from 'react';

export enum Position {
  topLeft = 'topLeft',
  topRight = 'topRight',
  bottomLeft = 'bottomLeft',
  bottomRight = 'bottomRight',
}

export interface DropdownProps {
  openOnMount?: boolean;
  children?: JSX.Element;
  content: JSX.Element;
  className?: string | string[];
  fullWidth?: boolean;
  show?: boolean;
  childRef?: RefObject<HTMLButtonElement | HTMLInputElement | HTMLDivElement>;
  position?: Position;
  disabled?: boolean;
  yOffset?: number;
  testId?: string;
  optionsTestId?: string;
  onClose?: () => void;
  onOpen?: () => void;
  'data-pwid'?: string;
}
