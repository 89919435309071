import React from 'react';

import { Button, Type as ButtonType, Variant } from '../Button';
import { Loading } from '../Loading';
import { appendClassProps } from '../util';
import { FormSaveProps } from './index.types';

/**
 - Use text for any text on the screen to conform to typography styles
 */
export const FormSave: React.FC<FormSaveProps> = ({
  className,
  onCancel,
  mode,
  status,
  disablePrimary,
  'data-pwid': dataPwId = 'form-save',
}: FormSaveProps) => {
  let primaryButtonLabel: string | JSX.Element = mode === 'edit' ? 'Save' : 'Create';
  if (status === 'loading') {
    primaryButtonLabel = <Loading type="small" />;
  } else if (status && status !== 'dirty') {
    primaryButtonLabel = 'Saved';
  }
  const secondaryButtonLabel: string | JSX.Element = mode === 'edit' ? 'Discard' : 'Back';

  return (
    <div className={`flex flex-row gap-2${appendClassProps(className)}`} data-pwid={dataPwId}>
      <Button
        type={ButtonType.reset}
        variant={Variant.secondary}
        onClick={onCancel}
        className="min-w-content"
        disabled={status !== 'dirty' && mode !== 'create'}
        data-pwid="form-save-cancel"
      >
        {secondaryButtonLabel}
      </Button>
      <Button
        type={ButtonType.submit}
        className="min-w-content"
        disabled={status !== 'dirty' || disablePrimary}
        data-pwid="form-save-submit"
      >
        {primaryButtonLabel}
      </Button>
    </div>
  );
};
