import {
  ChevronDoubleLeftIcon,
  ChevronDoubleRightIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
} from '@heroicons/react/outline';
import React, { useEffect, useState } from 'react';
import Skeleton from 'react-loading-skeleton';

import { Input } from '../Input';
import { Select, SelectValue } from '../Select';
import { Text, TextType } from '../Text';
import { appendClassProps } from '../util';
import { PaginationProps } from './index.types';

function clampPageNumber(pageNumber: number, totalCount: number, countPerPage: number) {
  return pageNumber === undefined ? 1 : Math.max(Math.min(pageNumber, Math.ceil(totalCount / countPerPage)), 1);
}

/**
 - Pagination component for rendering a page of data
 - Can provide any array of data objects and the table will only render the columns provided in the column prop
 */
export const Pagination: React.FC<PaginationProps> = ({
  className,
  pageNumber,
  countPerPage,
  totalCount,
  loading,
  mobile,
  onChangeCountPerPage,
  onChangePageNumber,
  'data-pwid': dataPwId = 'pagination',
}: PaginationProps) => {
  totalCount = totalCount ? totalCount : 0;
  const clampedPageNumber = clampPageNumber(pageNumber, totalCount, countPerPage);
  const [newPageNumber, setNewPageNumber] = useState<number | string>(clampedPageNumber);
  const first = Math.min((clampedPageNumber - 1) * countPerPage + 1, totalCount);
  const last = Math.min(Math.max(0, first + countPerPage - 1), totalCount);
  const pageCount = Math.ceil(totalCount / countPerPage) || 1;

  useEffect(() => {
    setNewPageNumber(clampedPageNumber);
  }, [clampedPageNumber]);

  const handleChangeCountPerPage = (count: SelectValue) => {
    if (onChangeCountPerPage) onChangeCountPerPage(parseInt(count as string));
  };

  const handleChangePageNumber = (page: string | number) => {
    setNewPageNumber(page !== '' ? parseInt(page as string) : page);
  };

  const handleSavePageNumber = () => {
    if (typeof newPageNumber === 'number') onChangePageNumber(clampPageNumber(newPageNumber, totalCount, countPerPage));
  };

  const handleSetFirstPage = () => {
    onChangePageNumber(1);
  };

  const handleSetPreviousPage = () => {
    if (clampedPageNumber > 1) {
      setNewPageNumber(clampedPageNumber - 1);
      onChangePageNumber(clampedPageNumber - 1);
    }
  };

  const handleSetLastPage = () => {
    onChangePageNumber(pageCount);
  };

  const handleSetNextPage = () => {
    if (clampedPageNumber < pageCount) {
      onChangePageNumber(clampedPageNumber + 1);
      setNewPageNumber(clampedPageNumber + 1);
    }
  };

  return (
    <div
      className={`flex flex-row w-full py-2 px-4 bg-blue-100 justify-center${appendClassProps(className)}`}
      data-testid="pagination"
      data-pwid={dataPwId}
    >
      {!mobile && (
        <div className="flex-1 flex items-center">
          {!!onChangeCountPerPage && (
            <Select
              className="w-fit"
              options={[
                { value: '20', label: '20 Per Page' },
                { value: '50', label: '50 Per Page' },
                { value: '100', label: '100 Per Page' },
              ]}
              value={countPerPage.toString()}
              onChangeValue={handleChangeCountPerPage}
              hideErrorSection
              placeholder="Select Count Per Page"
              clearable={false}
            />
          )}
        </div>
      )}
      <div className={`flex-initial flex flex-row gap-1`}>
        <button
          className="h-full flex flex-row items-center"
          onClick={handleSetFirstPage}
          disabled={loading || pageNumber === 1}
          data-pwid="pagination-first-page"
        >
          <ChevronDoubleLeftIcon
            className={`text-blue-800 h-8 w-8 ${loading || pageNumber === 1 ? 'opacity-30' : 'cursor-pointer'}`}
          />
        </button>
        <button
          className="h-full flex flex-row items-center"
          onClick={handleSetPreviousPage}
          disabled={loading || pageNumber === 1}
          data-pwid="pagination-previous-page"
        >
          <ChevronLeftIcon
            className={`text-blue-800 h-8 w-8 ${loading || pageNumber === 1 ? 'opacity-30' : 'cursor-pointer'}`}
          />
        </button>
        <div className="flex flex-row items-center gap-1">
          <Input
            data-testid="page-number"
            className="w-14"
            value={newPageNumber.toString()}
            onChangeValue={handleChangePageNumber}
            onBlur={handleSavePageNumber}
            onPressEnter={handleSavePageNumber}
            hideErrorSection
            data-pwid="pagination-page-number-input"
          />
          <Text
            type={TextType.custom}
            data-testid="of-pagecount"
            className="h-7 items-center text-base"
            data-pwid="pagination-of-pagecount"
          >
            of {pageCount ?? 0}
          </Text>
        </div>
        <button
          className="h-full flex flex-row items-center"
          onClick={handleSetNextPage}
          disabled={loading || pageNumber === pageCount}
          data-pwid="pagination-next-page"
        >
          <ChevronRightIcon
            className={`text-blue-800 h-8 w-8 ${loading || pageNumber === pageCount ? 'opacity-30' : 'cursor-pointer'}`}
          />
        </button>
        <button
          className="h-full flex flex-row items-center"
          onClick={handleSetLastPage}
          disabled={loading || pageNumber === pageCount}
          data-pwid="pagination-last-page"
        >
          <ChevronDoubleRightIcon
            className={`text-blue-800 h-8 w-8 ${loading || pageNumber === pageCount ? 'opacity-30' : 'cursor-pointer'}`}
          />
        </button>
      </div>
      {!mobile && (
        <Text
          type={TextType.custom}
          data-testid="records-count"
          className={`flex-1 justify-end items-center`}
          textClassName={`${loading ? ' w-20' : ''} text-sm md:text-base`}
          data-pwid="pagination-records-count"
        >
          {loading ? <Skeleton /> : `${first + '-' + last + ' of'} ${totalCount ?? 0}`}
        </Text>
      )}
    </div>
  );
};

export * from './index.types';
