import * as HeroIconsOutline from '@heroicons/react/outline';
import React, {
  ForwardedRef,
  forwardRef,
  MouseEventHandler,
  MutableRefObject,
  SVGProps,
  useCallback,
  useImperativeHandle,
  useMemo,
  useRef,
} from 'react';

import { Side } from '../types';
import { appendClassProps } from '../util';
import { ButtonProps, Variant } from './index.types';

// Component for conditionally forwarding ref
const UseOverrideRef = ({
  forwardedRef,
  buttonRef,
}: {
  forwardedRef: ForwardedRef<HTMLButtonElement>;
  buttonRef: MutableRefObject<HTMLButtonElement>;
}) => {
  useImperativeHandle(forwardedRef, () => {
    return {
      ...buttonRef?.current,
      focus: () => {
        buttonRef?.current?.focus();
      },
      getBoundingClientRect: () => buttonRef?.current?.getBoundingClientRect(),
      offsetParent: buttonRef?.current?.offsetParent,
    };
  });
  return null;
};
/**
 - Use button as a generic clickable component
 */
const Button = (
  {
    disabled = false,
    iconSide = Side.left,
    variant = Variant.primary,
    type,
    children,
    icon,
    className,
    size,
    tooltip,
    containerClassName,
    alignment,
    onClick,
    onClickIcon,
    'data-pwid': dataPwId = 'button',
  }: ButtonProps,
  ref: ForwardedRef<HTMLButtonElement>,
): JSX.Element => {
  const buttonRef = useRef<HTMLButtonElement>(null);
  let Icon: (props: SVGProps<SVGSVGElement>) => JSX.Element;
  if (icon) Icon = HeroIconsOutline[icon];

  const noOp = useCallback(() => {
    // no-op
  }, []);

  const handleClickIcon: MouseEventHandler<SVGSVGElement> = (e) => {
    if (onClickIcon) {
      e.stopPropagation();
      onClickIcon();
    }
  };

  const variantStyle = useMemo(() => {
    switch (variant) {
      case Variant.secondary:
        return `secondary-bg ${
          disabled ? 'opacity-50 cursor-default' : 'hover:border-gray-300'
        } border focus:border-blue-800`;
      case Variant.secondaryFilled:
        return `text-white bg-blue-800 ${disabled ? 'opacity-50 cursor-default' : 'hover:bg-blue-800'}`;
      case Variant.danger:
        return `text-white danger-bg ${disabled ? 'opacity-50 cursor-default' : 'danger-bg-hover'}`;
      case Variant.primary:
      default:
        return `text-white primary-bg ${disabled ? 'opacity-50 cursor-default' : 'primary-bg-hover'}`;
    }
  }, [disabled, variant]);

  const iconStyled = useMemo(
    () => {
      let classes = `-mx-1 w-7 h-7 sm:w-6 sm:h-6${onClickIcon ? ' hover:opacity-90' : ''}`;
      switch (size) {
        case 'small':
          classes = '-mx-[3px] w-5 h-5';
          break;
        case 'large': // TODO:
        default:
          break;
      }
      return icon ? <Icon data-testid="icon" className={`${classes} inline`} onClick={handleClickIcon} /> : null;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [size, icon],
  );

  const sizeClasses = useMemo(() => {
    switch (size) {
      case 'small':
        return 'min-w-[1.75rem] h-7 text-sm px-1';
      case 'large':
        return 'min-w-[2.25rem] h-14 text-2xl px-4';
      default:
        return 'min-w-[2.25rem] h-12 sm:h-9 text-lg sm:text-base px-4';
    }
  }, [size]);

  let alignmentClass = 'justify-center';
  if (alignment === 'right') {
    alignmentClass = 'justify-end';
  } else if (alignment === 'left') {
    alignmentClass = '';
  } else {
    alignmentClass = 'justify-center';
  }

  return (
    <div data-tip={tooltip} className={appendClassProps(containerClassName)}>
      {ref && Object.keys(ref).length > 0 ? (
        <UseOverrideRef forwardedRef={ref} buttonRef={buttonRef as MutableRefObject<HTMLButtonElement>} />
      ) : null}

      <button
        ref={buttonRef}
        type={type}
        disabled={disabled}
        data-testid="button"
        data-pwid={dataPwId}
        className={`whitespace-nowrap ${variantStyle} rounded-sm outline-none flex flex-row items-center ${alignmentClass} gap-2 ${sizeClasses} ${appendClassProps(
          className,
        )}`}
        onClick={onClick ? onClick : noOp}
      >
        {icon && iconSide === Side.left && iconStyled}
        {children}
        {icon && iconSide === Side.right && iconStyled}
      </button>
    </div>
  );
};

export * from './index.types';
export { Button as BareButton, WrappedButton as Button }; // for storybook (fails to properly parse component if wrapped with forwardRef)

const WrappedButton = forwardRef<HTMLButtonElement, ButtonProps>(Button);
