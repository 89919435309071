import React from 'react';

import { appendClassProps } from '../util';
import { FormProps } from './index.types';

/**
 - Use form as a wrapper around input and button elements for validation
 - and submission.
 */
export const Form: React.FC<FormProps> = ({
  preventDefault = false,
  method,
  children,
  className,
  onSubmit,
}: FormProps) => {
  const handleSubmit = preventDefault
    ? (e: React.FormEvent) => {
        e.preventDefault();
        onSubmit(e);
      }
    : onSubmit;

  return (
    <form data-testid="form" className={appendClassProps(className)} onSubmit={handleSubmit} method={method}>
      {children}
    </form>
  );
};

export * from './index.types';
