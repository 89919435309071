import React, { ChangeEvent, RefObject } from 'react';

import { HeroIcons } from '../style/heroicons';

export enum TextAreaType {
  text = 'text',
  password = 'password',
  number = 'number',
  textArea = 'textArea',
}
export interface TextAreaProps {
  ref?: RefObject<HTMLTextAreaElement>;
  label?: string;
  leftIcon?: HeroIcons;
  rightIcon?: HeroIcons;
  tooltip?: string;
  placeholder?: string;
  autoFocus?: boolean;
  errorMessage?: string;
  revealErrorMessage?: boolean;
  value?: string;
  className?: string | string[];
  inputClassName?: string | string[];
  name?: string;
  disabled?: boolean;
  hideErrorSection?: boolean;
  loading?: boolean;
  rows?: number;
  onChange?: (event: ChangeEvent<HTMLTextAreaElement>) => void;
  onChangeValue?: (value: string) => void;
  onPressEnter?: (e: React.FormEvent) => void;
  onBlur?: () => void;
  onClickRightIcon?: () => void;
  'data-pwid'?: string;
}
