import React, { ChangeEvent, useState } from 'react';

import { Error } from '../Error';
import { Label } from '../Label';
import { appendClassProps } from '../util';
import { CheckboxProps } from './index.types';

export const Checkbox: React.FC<CheckboxProps> = ({
  checked = false,
  revealErrorMessage = false,
  disabled = false,
  hideErrorSection = false,
  id,
  label,
  labelColor,
  errorMessage,
  children,
  tooltip,
  className,
  indeterminate = false,
  onChangeValue,
  'data-pwid': dataPwid,
}: CheckboxProps) => {
  const [dirty, setDirty] = useState(false);
  const onChange = (event: ChangeEvent<HTMLInputElement>) => {
    event.stopPropagation();
    const target = event.target as HTMLInputElement;
    if (typeof onChangeValue === 'function') {
      onChangeValue(target.checked);
    }
    setDirty(true);
  };

  const handleRefIndeterminate = (el: HTMLInputElement) => {
    if (el) {
      el.indeterminate = indeterminate;
    }
  };

  const showErrorMessage = errorMessage && (revealErrorMessage || dirty) ? true : false;

  return (
    <div className={`flex flex-col${appendClassProps(className)}`} onClick={(e) => e.stopPropagation()}>
      <div className="flex flex-row gap-2 items-center">
        <>
          <div>
            <input
              className={`cursor-pointer text-blue-800 focus:ring-0 focus:ring-offset-0 focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-blue-800 focus-visible:ring-offset-0 focus-visible:ring-offset-white${
                disabled ? ' !bg-gray-150 cursor-not-allowed' : ''
              }`}
              type="checkbox"
              data-testid="checkbox"
              onChange={onChange}
              checked={checked ?? false}
              disabled={disabled}
              id={id ?? label}
              data-pwid={dataPwid || label || 'checkbox'}
              ref={handleRefIndeterminate}
            />
          </div>
          {label ? (
            <>
              <label htmlFor={id ?? label} className="flex-1">
                <Label
                  className={`${labelColor ? labelColor : ''} ${disabled ? 'cursor-not-allowed' : 'cursor-pointer'}`}
                  tooltip={tooltip}
                  textColor={labelColor}
                  wrap
                >
                  {label}
                </Label>
              </label>
            </>
          ) : (
            children
          )}
        </>
      </div>
      {!hideErrorSection && <Error errorMessage={errorMessage} showErrorMessage={showErrorMessage} />}
    </div>
  );
};

export * from './index.types';
