import '../style/react-date-range/styles.css'; // main css file
import '../style/react-date-range/theme/default.css'; // theme css file

import { DateTime } from 'luxon';
import React, { useEffect, useRef, useState } from 'react';
import { DateRangePicker as ReactDateRangePicker, RangeKeyDict } from 'react-date-range';

import { Button, Variant } from '../Button';
import { Input, InputType } from '../Input';
import { Text, TextType } from '../Text';
import { Side } from '../types';
import { appendClassProps } from '../util';
import { useMobile, useOutsideAlerter } from '../util/hooks';
import { DateRangePickerProps } from './index.types';

/**
 - Use DateRangePicker to select a date range
 */
export const DateRangePicker: React.FC<DateRangePickerProps> = ({
  side,
  dateRange,
  title,
  className,
  startPlaceholder,
  endPlaceholder,
  minDate: minDateProp,
  maxDate: maxDateProp,
  maxDays,
  showOnInit = false,
  changeOnClose = true,
  onChange,
  'data-pwid': dataPwid = 'date-range-picker',
}: DateRangePickerProps) => {
  const wrapperRef = useRef(null);
  const isMobile = useMobile();
  const [minDate, setMinDate] = useState(minDateProp);
  const [maxDate, setMaxDate] = useState(maxDateProp);
  const [from, setFrom] = useState<Date | undefined>(dateRange?.from as Date);
  const [to, setTo] = useState<Date | undefined>(dateRange?.to as Date);
  const [showMenu, setShowMenu] = useState(showOnInit);

  const handleBlur = () => {
    onChange({
      from,
      to,
    });
  };

  useEffect(() => {
    setFrom(dateRange?.from as Date);
    setTo(dateRange?.to as Date);
  }, [dateRange]);

  useEffect(() => {
    if (isMobile || !changeOnClose) handleBlur();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [from, to, isMobile, changeOnClose]);

  const handleClickWrapper = () => {
    if (showMenu) {
      setShowMenu(false);
      if (changeOnClose) handleBlur();
    }
  };

  useOutsideAlerter(isMobile ? null : wrapperRef, handleClickWrapper);

  const handleClickDateIcon = () => {
    setShowMenu(true);
  };
  const handleChange = (item: RangeKeyDict) => {
    const start = item.selection.startDate ? DateTime.fromJSDate(item.selection.startDate).startOf('day') : undefined;
    let end = item.selection.endDate ? DateTime.fromJSDate(item.selection.endDate).endOf('day') : undefined;
    if (maxDays) {
      // on first selection
      let min, max;
      if (end && start?.equals(end)) {
        // restrict to maxDays
        min = start.minus({ days: maxDays }).toJSDate();
        max = start.plus({ days: maxDays }).toJSDate();
      } else {
        // don't restrict to maxDays
        min = undefined;
        max = undefined;
      }
      if (end && start && end.diff(start, 'days').days > maxDays) {
        end = start.plus({ days: maxDays });
      }
      setMinDate(min);
      setMaxDate(max);
    }

    setFrom(start?.toJSDate());
    setTo(end?.toJSDate());
  };

  const datePick = (
    <div ref={wrapperRef} data-pwid={dataPwid}>
      <ReactDateRangePicker
        onChange={handleChange}
        months={2}
        ranges={[
          {
            startDate: from,
            endDate: to,
            key: 'selection',
          },
        ]}
        direction="horizontal"
        minDate={minDate}
        maxDate={maxDate}
        showPreview={false}
        editableDateInputs
      />
    </div>
  );

  if (showOnInit) {
    return datePick;
  }

  return (
    <div className={`relative${appendClassProps(className)}`} data-testid="datePicker">
      {title && <Text type={TextType.h6}>{title}</Text>}
      {isMobile ? (
        <>
          <Input
            type={InputType.date}
            value={from ? DateTime.fromJSDate(from).toFormat('yyyy-LL-dd') : startPlaceholder}
            onChange={(e) => {
              handleChange({
                selection: {
                  startDate: e.target.value ? DateTime.fromFormat(e.target.value, 'yyyy-LL-dd').toJSDate() : undefined,
                  endDate: to,
                },
              });
            }}
            hideErrorSection
          />
          <Input
            type={InputType.date}
            value={to ? DateTime.fromJSDate(to).toFormat('yyyy-LL-dd') : startPlaceholder}
            onChange={(e) => {
              handleChange({
                selection: {
                  startDate: from,
                  endDate: e.target.value ? DateTime.fromFormat(e.target.value, 'yyyy-LL-dd').toJSDate() : undefined,
                },
              });
            }}
            hideErrorSection
          />
        </>
      ) : (
        <div ref={wrapperRef}>
          <Button variant={Variant.secondary} onClick={handleClickDateIcon}>{`${
            from ? DateTime.fromJSDate(from).toLocaleString(DateTime.DATE_MED) : startPlaceholder
          } - ${to ? DateTime.fromJSDate(to).toLocaleString(DateTime.DATE_MED) : endPlaceholder}`}</Button>
          <div
            className={`shadow-md z-10 text-blue-800 flex flex-col gap-2 absolute top-8 ${
              side === Side.right ? 'right-0' : 'left-0'
            } rounded-tl-sm rounded-b-sm ${showMenu ? '' : ' hidden'}`}
          >
            {datePick}
          </div>
        </div>
      )}
    </div>
  );
};

export * from './index.types';
