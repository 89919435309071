import * as HeroIconsOutline from '@heroicons/react/outline';
import debounce from 'debounce-promise';
import DOMPurify from 'dompurify';
import React, { ReactNode, useCallback, useEffect, useMemo, useState } from 'react';
import Skeleton from 'react-loading-skeleton';
import {
  components,
  DropdownIndicatorProps,
  InputProps,
  OptionProps,
  SingleValueProps,
  StylesConfig,
  Theme,
  ValueContainerProps,
} from 'react-select';
import AsyncSelectRS from 'react-select/async';

import { Error } from '../Error';
import { Label } from '../Label';
import colors from '../style/colors';
import { appendClassProps } from '../util';
import { AsyncSelectProps, ReactSelectOption } from './index.types';

const ValueContainerFactory =
  (icon: ReactNode) =>
  // eslint-disable-next-line react/display-name
  ({ children, ...props }: ValueContainerProps) => {
    return (
      <components.ValueContainer {...props}>
        <div className="absolute left-2">{icon}</div>
        {children}
      </components.ValueContainer>
    );
  };

const SingleValue = (props: SingleValueProps) => (
  <components.SingleValue {...props}>{(props.children as string)?.replaceAll(/<b>|<\/b>/g, '')}</components.SingleValue>
);

const DropdownIndicator = (props: DropdownIndicatorProps) => (
  <components.DropdownIndicator {...props}>
    <HeroIconsOutline.ChevronDownIcon className="h-5 w-5 lg:h-6 lg:w-6" />
  </components.DropdownIndicator>
);

const Option = (props: OptionProps) => {
  const data = props.data as Record<string, unknown>;
  return (
    <components.Option {...props}>
      <span
        data-pwid={data ? data['label'] || data['value'] : ''}
        dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(props.children as string, { ALLOWED_TAGS: ['b'] }) }}
      ></span>
    </components.Option>
  );
};

const Input = (props: InputProps) => <components.Input {...props} isHidden={false} />;

export const AsyncSelect: React.FC<AsyncSelectProps> = ({
  disabled = false,
  loading = false,
  searchable = true,
  clearable = false,
  menuPlacement = 'auto',
  label,
  value,
  onChange,
  onLoadOptions,
  filterOption,
  errorMessage,
  revealErrorMessage,
  tooltip,
  className,
  hideErrorSection,
  placeholder,
  autoFocus,
  singleSelectMode,
  icon,
  'data-pwid': dataPwid,
}: AsyncSelectProps) => {
  const [dirty, setDirty] = useState(false);
  const [_placeholder, setPlaceholder] = useState(placeholder);
  const [_value, setValue] = useState<ReactSelectOption | undefined | null>();

  useEffect(() => {
    let clone = value;
    if (value) clone = { ...value };
    setValue(clone);
  }, [value]);

  useEffect(() => {
    setPlaceholder(placeholder);
  }, [placeholder]);

  const showErrorMessage = errorMessage && (revealErrorMessage || dirty) ? true : false;

  const handleChange = (newValue: unknown) => {
    setDirty(true);
    if (onChange) onChange(newValue as ReactSelectOption);
  };

  const handleBlur = () => {
    setDirty(true);
  };

  const styles: StylesConfig = {
    input: (base) => ({
      ...base,
      padding: '0',
      margin: '0',
      color: colors.blue['800'],
    }),

    control: (base) => ({
      ...base,
      height: '3rem',
      minHeight: '34px',
      boxShadow: 'none',
      backgroundColor: disabled ? colors.gray['150'] : colors.white,
      color: colors.gray['400'],
      borderRadius: '2px',
    }),

    valueContainer: (base) => ({
      ...base,
      padding: icon ? '0 2.25rem' : '0 0.25rem',
    }),

    singleValue: (base) => ({
      ...base,
      color: colors.blue['800'],
    }),

    dropdownIndicator: (base) => ({
      ...base,
      height: '34px',
      alignItems: 'center',
      padding: '0 0.25rem 0 0.25rem',
    }),

    clearIndicator: (base) => ({
      ...base,
      height: '34px',
      alignItems: 'center',
      padding: '0 0.25rem 0 0.25rem',
    }),

    indicatorSeparator: (base) => ({
      ...base,
      display: 'none',
    }),

    menu: (base) => ({
      ...base,
      color: colors.blue['800'],
      height: '300px',
    }),

    placeholder: (inlineCss) => ({
      ...inlineCss,
      whiteSpace: 'nowrap',
    }),
  };

  const theme = (theme: Theme) => ({
    ...theme,
    colors: {
      ...theme.colors,
      primary: colors.blue['800'],
      primary75: colors.blue['600'],
      primary50: colors.blue['400'],
      primary25: colors.blue['200'],

      danger: colors.red['500'],
      dangerLight: colors.red['300'],

      neutral0: colors.white,
      neutral5: colors.gray['50'],
      neutral10: colors.gray['100'],
      neutral20: colors.gray['200'],
      neutral30: colors.gray['300'],
      neutral40: colors.gray['400'],
      neutral50: colors.gray['500'],
      neutral60: colors.gray['600'],
      neutral70: colors.gray['700'],
      neutral80: colors.gray['800'],
      neutral90: colors.gray['900'],
    },
  });

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const onLoadOptionsDebounced = useCallback(debounce(onLoadOptions, 500), [onLoadOptions]);

  const handleLoadOptions = async (searchTerm: string): Promise<ReactSelectOption[]> => {
    const results = await onLoadOptionsDebounced(searchTerm);
    if (results) return results;
    return [];
  };

  const ValueContainer = useMemo(() => ValueContainerFactory(icon), [icon]);

  return (
    <div
      data-testid="asyncselect"
      className={`${className?.includes('absolute') ? '' : 'relative'}${appendClassProps(className)}`}
      data-pwid={dataPwid}
    >
      {label && (
        <Label dataTestId="label" tooltip={tooltip}>
          {label}
        </Label>
      )}
      {loading ? (
        <Skeleton className="w-full" height={34} />
      ) : (
        <AsyncSelectRS
          // ref={asyncSelectRef}
          // cacheOptions // doesn't work with debouncing atm, there is an open issue: https://github.com/JedWatson/react-select/issues/4645
          defaultOptions
          isSearchable={searchable}
          loadOptions={handleLoadOptions}
          filterOption={filterOption}
          isClearable={clearable}
          styles={styles}
          theme={theme}
          onChange={handleChange}
          components={{
            ValueContainer,
            DropdownIndicator,
            Input,
            Option,
            SingleValue,
          }}
          onBlur={handleBlur}
          placeholder={_placeholder}
          autoFocus={autoFocus}
          value={singleSelectMode ? undefined : _value}
          isDisabled={disabled}
          blurInputOnSelect
          maxMenuHeight={300}
          minMenuHeight={300}
          menuPlacement={menuPlacement}
          menuShouldScrollIntoView
          data-pwid="async-select-input"
        />
      )}
      {!hideErrorSection && <Error errorMessage={errorMessage} showErrorMessage={showErrorMessage} />}
    </div>
  );
};

export * from './index.types';
